import {
  getDefaultProductItemVariant,
  getDefaultProductVariant
} from "../../helpers/product";
import { ProductListItemType, ProductType } from "../../types/airgraft";

type ScoredProductListItemType = ProductListItemType & {
  score?: number;
};

export const filterRecommendedProducts = (
  product: ProductType,
  allRecommendedProducts: ProductListItemType[],
  limit: number
): ProductListItemType[] => {
  const productVariant = getDefaultProductVariant(product);

  return (allRecommendedProducts as ScoredProductListItemType[])
    .map(recommendedProduct => {
      const recommendedProductVariant = getDefaultProductItemVariant(
        recommendedProduct
      );
      recommendedProduct.score = 0;

      // Same product type
      if (recommendedProduct.productType === product.productType) {
        recommendedProduct.score += 20;
      }

      // Same brand
      if (
        recommendedProductVariant.brand.name ===
        recommendedProductVariant.brand.name
      ) {
        recommendedProduct.score += 15;
      }

      const availableRecommendedVariant = getDefaultProductItemVariant(
        recommendedProduct
      );

      // Same Oil plant species (Ex: "Sativa")
      if (!availableRecommendedVariant) {
        recommendedProduct.score = -1;
      } else if (
        product.productType === "oil" &&
        recommendedProduct.productType === "oil" &&
        availableRecommendedVariant?.variantDetail?.plantSpecies &&
        productVariant?.variantDetail?.plantSpecies ===
          availableRecommendedVariant?.variantDetail?.plantSpecies
      ) {
        recommendedProduct.score += 10;
      }

      return recommendedProduct;
    })
    .filter(item => item.score !== -1)
    .sort((a, b) => (a.score < b.score ? 1 : a.score > b.score ? -1 : 0))
    .slice(0, limit);
};
