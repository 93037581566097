import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { PriceType } from "../../../../types/airgraft";

type Props = {
  title: string;
  price: PriceType;
  sku: string;
  brand?: string;
};

const SeoStructuredSchema = ({ title, price, sku, brand }: Props) => {
  const siteUrl = process.env.SITE_URL;
  const location = useLocation();

  return (
    <Helmet>
      <script type="application/ld+json">
        {`
      {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": "${title}",
        "sku": "${sku}",
        "brand": {
          "@type": "Thing",
          "name": "${brand || "Airgraft"}"
        },
        "offers": {
          "@type": "Offer",
          "url": "${siteUrl}${location.pathname}",
          "priceCurrency": "${price ? price.currencyCode : null}",
          "price": "${price ? price.amount : null}",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock",
          "seller": {
            "@type": "Organization",
            "name": "Airgraft"
          }
        }
      }
    `}
      </script>
    </Helmet>
  );
};

export default SeoStructuredSchema;
