import { Box, Container } from "@material-ui/core";
import { graphql } from "gatsby";
import React from "react";
import BackButton from "../../components/back-navigation/BackButton";
import NotFound from "../../components/not-found";
import Page from "../../components/page";
import Seo from "../../components/seo";
import { replaceSeoMetaTagsTitle } from "../../components/seo/utils";
import ProductDetails from "../../containers/shop/ProductDetails";
import SeoStructureSchema from "../../containers/shop/ProductDetails/components/SeoStructuredSchema";
import ProductsListSection from "../../content-modules/products-list-section";
import {
  filterProductsAvailableForSubcountry,
  getDefaultProductVariant
} from "../../helpers/product";
import { SHOP_DISCOVER_PATH } from "../../helpers/url";
import useCountry from "../../hooks/use-country";
import useFeatureFlag from "../../hooks/use-feature-flag";
import useSubcountry from "../../hooks/use-subcountry";
import useTranslate from "../../hooks/use-translate";
import { ProductType } from "../../types/airgraft";
import { filterRecommendedProducts } from "./utils";

type Props = {
  data: Queries.ProductDetailsPageDataQuery;
};

/**
 * Template for stand-alone product details pages Ex. /pods/papaya
 */
const ProductDetailPageTemplate: React.FunctionComponent<Props> = ({
  data
}) => {
  const t = useTranslate();
  const country = useCountry();
  const { subcountry } = useSubcountry();

  const isShopDiscoverEnabled = useFeatureFlag("SHOP_DISCOVER");
  const isShopPLPEnabled = useFeatureFlag("SHOP_PLP");
  const product = data.datoCmsProduct as ProductType;
  const productVariant = getDefaultProductVariant(product);
  if (!productVariant || product.disablePdp) {
    return <NotFound noLogo />;
  }

  // "See more" recommended products
  let recommendedProductItems = filterProductsAvailableForSubcountry(
    data.recommendedProducts.nodes,
    country,
    subcountry
  );
  recommendedProductItems = filterRecommendedProducts(
    product,
    recommendedProductItems,
    8
  );

  // Product details page title is generated automatically to be consistent.
  let pageTitle = `${productVariant.title} ${t("by")} ${productVariant.brand.name
    }`;
  if (productVariant.brand.slug === "airgraft") {
    pageTitle = productVariant.title;
  }

  return (
    <Page container="fluid">
      {/* SEO */}
      {product.seoMetaTags && (
        <Seo
          seoMetaTags={replaceSeoMetaTagsTitle(
            product.seoMetaTags,
            `${pageTitle} | AIRGRAFT 2`
          )}
        />
      )}

      {/* E-commerce data */}
      <SeoStructureSchema
        title={productVariant.title}
        brand={productVariant.brand?.name}
        sku={product.slug}
        price={{ amount: productVariant.price, currencyCode: "USD" }}
      />

      {/* Back to shop */}
      <Box mt={{ xs: 2, md: 3 }}>
        <Container maxWidth="md">
          {isShopDiscoverEnabled && (
            <BackButton to={SHOP_DISCOVER_PATH}>
              {t("shop.backToShop")}
            </BackButton>
          )}
          {!isShopDiscoverEnabled && isShopPLPEnabled && (
            <BackButton to={"/shop/products"}>
              {t("shop.backToShop")}
            </BackButton>
          )}
          {!isShopDiscoverEnabled && !isShopPLPEnabled && (
            <BackButton to={"/"}>{t("back")}</BackButton>
          )}
        </Container>
      </Box>

      {/* Product details */}
      <ProductDetails product={product} />

      {/* Recommended products */}
      <Box mb={{ xs: 4, md: 8 }}>
        <Container maxWidth="xl">
          <ProductsListSection
            title={t("shop.otherProducts")}
            products={recommendedProductItems}
          />
        </Container>
      </Box>
    </Page>
  );
};

export const query = graphql`
  query ProductDetailsPageData($locale: String!, $originalId: String!) {
    datoCmsProduct(originalId: { eq: $originalId }, locale: { eq: $locale }) {
      ...DatoCmsProductFragment
    }
    recommendedProducts: allDatoCmsProduct(
      filter: {
        originalId: { ne: $originalId }
        locale: { eq: $locale }
        disablePlp: { ne: true }
      }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        ...DatoCmsProductItemFragment
      }
    }
  }
`;

export default ProductDetailPageTemplate;
